import React from 'react';
import { Layout } from 'antd';
import AppSidebar from './sidebar';
import AppHeader from './header';

export default function AppLayout({ children }: any) {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <AppSidebar />
        <Layout style={{ backgroundColor: '#ffffff' }}>
          <AppHeader />
          <Layout.Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280
            }}
          >
            {/* <p style={{ backgroundColor: '#ff6a6a', padding: '5px' }}>
              AICF PRS services are temporarily unavailable due to annual maintenance for the year
              change. Services will resume by 8 PM IST on April 1, 2025.{' '}
            </p> */}
            {children}
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
